(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/selfservice-storage-handler/assets/javascripts/storage-handler.js') >= 0) return;  svs.modules.push('/components/components/selfservice-storage-handler/assets/javascripts/storage-handler.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  Storage
} = svs.components;
const {
  log
} = svs.core;
const logger = log.getLogger('selfservicestoragehandler:storagehandler');
const TENANT_QUICKPICK_NAMES = {
  lb: 'pix',
  tb: 'huxflux'
};
const CART_EVENT_LABELS = {
  ADD: 'add_to_cart',
  REMOVE: 'remove_from_cart',
  CLEAR: 'clear_cart'
};
function trackEvent(_ref) {
  let {
    eventLabel,
    items = []
  } = _ref;
  const eventItems = items.map(_ref2 => {
    var _wagers$;
    let {
      productProperties,
      wagers
    } = _ref2;
    const productId = Array.isArray(wagers) ? ((_wagers$ = wagers[0]) === null || _wagers$ === void 0 || (_wagers$ = _wagers$.bets[0]) === null || _wagers$ === void 0 ? void 0 : _wagers$.productId) || productProperties.productFlag : productProperties.productFlag;
    const variant = productProperties.isQuickPick && TENANT_QUICKPICK_NAMES[productProperties.tenant] || 'manuell';
    const quantity = Array.isArray(wagers) ? wagers.length : 1;
    const productName = productProperties.addonProductName ? productProperties.addonProductName : productProperties.productTitle;
    return new svs.components.analytics.EcommerceEventItem(productId, productName, productProperties.cost, productProperties.tenant, variant, quantity);
  });
  const ecommerceEvent = new svs.components.analytics.EcommerceEvent(eventLabel, eventItems, eventItems.length > 1 ? 'multiple' : 'single');
  svs.components.analytics.trackEcommerceEvent(ecommerceEvent);
}
function readFromBrowser() {
  return Storage.browser.get('wagers', 'selfServiceCart') || [];
}
function runCallback(cartObj, callback, requestId) {
  callback({
    cartObject: cartObj,
    requestId
  });
}
function storeToBrowser(cartObj, callback, requestId) {
  Storage.browser.set('wagers', 'selfServiceCart', cartObj, svs.components.Storage.constants.EXPIRES_24H, () => {
    runCallback(cartObj, callback, requestId);
  });
}
function addDataToItem(item, id, tenant) {
  item.id = id;
  item.productProperties.timestamp = new Date();
  item.productProperties.tenant = tenant;
}
function addItemToWagerBucket(item, wagersBucket) {
  if (!item.wagers && (!item.wagerString || item.wagerString.length < 1) || !item.productProperties || !item.productProperties.productFlag || !item.productProperties.cost) {
    logger.warn(new Error('Failed to add item to cart because its lacking one or all productProperties and/or wager'));
    return;
  }
  wagersBucket.unshift(item);
  trackEvent({
    eventLabel: CART_EVENT_LABELS.ADD,
    items: [item]
  });
}
function clearStorage(tenant, callback, requestId) {
  logger.debug("clearing localstorage for tenant ".concat(tenant));
  localStorage.clear();
  runCallback([], callback, requestId);
}
svs.components.selfserviceStorageHandler = _objectSpread(_objectSpread({}, svs.components.selfserviceStorageHandler), {}, {
  actions: {
    addItem: callback => (tenant, data, requestId) => {
      if (data) {
        const cartObj = readFromBrowser();
        let cartIdCounter = Storage.browser.get('highestId', 'selfServiceCart') || 0;
        if (!data.id) {
          addDataToItem(data, ++cartIdCounter, tenant);
        }
        addItemToWagerBucket(data, cartObj, tenant);
        Storage.browser.set('highestId', 'selfServiceCart', cartIdCounter, svs.components.Storage.constants.EXPIRES_24H, () => {
          storeToBrowser(cartObj, callback, requestId);
        });
      }
    },
    addItems: callback => (tenant, data, requestId) => {
      const il = data ? data.length || 0 : 0;
      if (il > 0) {
        const cartObj = readFromBrowser();
        let cartIdCounter = Storage.browser.get('highestId', 'selfServiceCart') || 0;
        for (let i = 0; i < il; i++) {
          if (!data[i].id) {
            addDataToItem(data[i], ++cartIdCounter, tenant);
          }
          addItemToWagerBucket(data[i], cartObj, tenant);
        }
        Storage.browser.set('highestId', 'selfServiceCart', cartIdCounter, svs.components.Storage.constants.EXPIRES_24H, () => {
          storeToBrowser(cartObj, callback, requestId);
        });
      }
    },
    removeItem: callback => (tenant, data, requestId) => {
      if (typeof data !== 'number') {
        logger.info(new Error('Cant remove item thats undefined or lacking id'));
        return;
      }
      const cartItems = readFromBrowser();
      const remainingItems = cartItems.filter(item => item.id !== data);
      storeToBrowser(remainingItems, callback, requestId);
    },
    clearItems: callback => (tenant, data, requestId) => {
      storeToBrowser([], callback, requestId);
    },
    getItems: callback => (tenant, data, requestId) => {
      runCallback(readFromBrowser(), callback, requestId);
    },
    clearStorage: callback => (tenant, data, requestId) => {
      clearStorage(tenant, callback, requestId);
    }
  }
});

 })(window);